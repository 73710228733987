<template>
  <div class="container features-container">
    <div class="row d-flex justify-content-center">
      <div class="col-md-3 col-6">
        <Feature
          :imageSrc="'images/features/users.png'"
          :title="'QUIENES SOMOS'"
          :subtitle="'Somos una Coorporación Internacional'"
          :location="'/about'"
        />
      </div>
      <div class="col-md-3 col-6">
        <Feature
          :imageSrc="'images/features/auction.png'"
          :title="'ENCUENTRA EL REMATE DE TU INTERES'"
          :subtitle="'Las mejores ofertas'"
          :location="'/auctions'"
        />
      </div>
      <div class="col-md-3 col-6">
        <Feature
          :imageSrc="'images/features/contact-book.png'"
          :title="'REMATA CON NOSOTROS'"
          :subtitle="'Vende al mejor postor'"
          :location="'/contact'"
        />
      </div>
      <div class="col-md-3 col-6">
        <Feature
          :imageSrc="'images/features/usuario.png'"
          :title="'REGISTRATE EN LA WEB'"
          :subtitle="'Compra de manera segura'"
          :location="'/login'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Feature from "../../atoms/Characteristic/Characteristic.vue";

export default {
  components: {
    Feature,
  },
};
</script>
<style scoped="scoped">
.feature-col {
  padding: 0px !important;
}
@media screen and (max-width: 768px) {
  .section-feature {
    display: block !important;
  }
  .feature {
    display: block !important;
  }
  .features-container {
    display: flex;
    justify-content: center !important;
  }
  :deep(.feature) {
    margin: 10px !important;
    justify-content: center;
  }
}
</style>
